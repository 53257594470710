import React from 'react';
import styles from './index.module.css'
import ContactForm from '../Form'
import BusCard from '../../BusCard'

export default function ContactContent() {
  return (
    <div>
      <div className={styles.headMsg}>
        Get in touch with us today so we can start discussing your project requirements. We would be proud to deliver a great solution which produces positive impacts for your business and community. Let us know if you have any questions about services that we offer.
      </div>
      <div className={styles.main}>
        <div className={styles.busCardCtr}>
          <BusCard />
        </div>
        <ContactForm />
      </div>
    </div>
  )
}
