import React, { useEffect, useState } from 'react';
import styles from './index.module.css'
import { c, useLoaded } from '../../util'

export default function PageHeading({ bgImg, symbolImg, title }) {

  const isIn = useLoaded();

  return (
    <div className={styles.root} style={{ backgroundImage: `url(${bgImg})` }}>
      <div className={c([styles.content, isIn && styles.contentIn])}>
        <img src={symbolImg} alt="Projects" className={styles.img} />
        <h1>{title}</h1>
      </div>
    </div>
  );
}
