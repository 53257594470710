import React from 'react'
import Layout from '../components/Layout'
import PageHeading from '../components/PageHeading'
import worldImg from '../assets/world.jpg'
import mailImg from '../assets/mail2.svg'
import ContactContent from '../components/Contact/ContactContent'
import Meta from '../components/Meta'

const META_DESCRIPTION = 'We hope to pique your interest with our experience and expertise. Get in touch with us so we can work on your next great idea!'

export default function Contact() {
  return (
    <Layout>
      <Meta pageName='Contact' description={META_DESCRIPTION}/>
      <PageHeading bgImg={worldImg} symbolImg={mailImg} title='Get in touch!' />
      <ContactContent />
    </Layout>
  );
}
