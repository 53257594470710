import React from 'react';
import styles from './index.module.css';
import meImg from '../../assets/me.jpg'

export default function BusCard() {
  return (
    <div className={styles.busCard}>
      <a target="_blank" href="https://andries.ca">
        <div>Darnell Andries</div>
      </a>
      {/* <div className={styles.busDiv}></div> */}
      <div>Sales / Software Architect</div>
      <img src={meImg} alt="Darnell" />
    </div>
  );
}
