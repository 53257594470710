import React, { useRef, useState } from 'react';
import styles from './index.module.css';
import axios from 'axios';

const MESSAGE_POST_URL = 'https://6afvswctdh.execute-api.us-west-2.amazonaws.com/main/message';
const MESSAGE_API_KEY = '2XEvZAzjGY6cnwSlo5Nm51yZGOS4SIKy661m9dMw';

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const fields = [
  {
    name: 'name',
    label: 'Name:',
    type: 'text',
    maxlength: 511,
    validate: (v) => !v && 'Please enter your name.'
  },
  {
    name: 'email',
    label: 'Email:',
    type: 'text',
    maxlength: 511,
    validate: (v) => (!v || !validateEmail(v)) && 'Please enter a valid email.'
  },
  {
    name: 'company',
    label: 'Company (optional):',
    type: 'text',
    maxlength: 511
  },
  {
    name: 'phone',
    label: 'Phone number (optional):',
    type: 'text',
    maxlength: 511
  },
  {
    name: 'message',
    label: 'Message:',
    type: 'textarea',
    maxlength: 49999,
    validate: (v) => !v && 'Please enter your message.'
  },
];

function captureFormValues(form) {
  const result = {};
  for (const element of form.elements) {
    if (element.type === 'submit') {
      continue;
    }
    result[element.name] = element.value;
  }
  return result;
}

export default function Form() {
  const formRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [sendStatus, setSendStatus] = useState(0);

  function handleSubmit(ev) {
    ev.preventDefault();
    setErrors({});
    const newErrors = {};

    const formData = captureFormValues(formRef.current);
    
    // Validate
    for (const field of fields) {
      if (!field.validate) {
        continue;
      }
      const validationResult = field.validate(formData[field.name]);
      if (!!validationResult) {
        newErrors[field.name] = validationResult;
      }
    }

    if (Object.keys(newErrors) > 0) {
      setErrors(newErrors);
      return;
    }

    // Sending ...
    setSendStatus(1);

    axios({
      method: 'post',
      url: MESSAGE_POST_URL,
      data: formData,
      headers: { 'x-api-key': MESSAGE_API_KEY }
    }).then(() => {
      // Sent!
      setSendStatus(2);
    }, (e) => {
      // Fail!
      const errorText = e.response && e.response.data && e.response.data.message;
      newErrors.general = 'Failed to send message ';
      if (!!errorText) {
        newErrors.general += `(${errorText})`;
      }
      setErrors(newErrors);
      setSendStatus(0);
    });
  }

  let submitText;
  switch (sendStatus) {
    case 1:
      submitText = 'Sending...';
      break;
    case 2:
      submitText = 'Sent!';
      break;
    default:
      submitText = 'Send';
  }

  return (
    <div className={styles.formCtr}>
      <form ref={formRef} onSubmit={handleSubmit}>
        {fields.map((v) => (
          <div key={v.name} className={!!errors[v.name] ? styles.error : ''}>
            <label htmlFor={v.name}>{v.label}</label>
            {v.type === 'textarea' ? (
              <textarea rows='21' name={v.name} maxLength={v.maxlength} />
            ) : (
              <input name={v.name} type={v.type} maxLength={v.maxlength} />   
            )}
            {!!errors[v.name] && (
              <div className={styles.errorText}>
                {errors[v.name]}
              </div>
            )}
          </div>
        ))}
        {!!errors.general && (
          <div className={styles.errorText}>{errors.general}</div>
        )}
        <div className={styles.submitCtr}>
          <input type="submit" disabled={sendStatus > 0} value={submitText} />
        </div>
      </form>
      <div className={styles.altContact}>
        Or shoot us an email at <a href="mailto:sales@torquedev.xyz">sales@torquedev.xyz</a>.
      </div>
    </div>

  );

}
